html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  /* min-width: 768px;
  max-width: 1366px;
  margin: auto auto; */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  color: 'black';
  /* min-width: 768px;
  max-width: 1366px;
  margin: auto auto; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.main-landing-page {
  min-width: 768px;
  max-width: 1366px;
  margin: auto auto; 
}

.full-screen {
  height: 100vh;
  overflow: hidden;
}

.full-box {
  display: flex;
  flex-direction: column;
}

.full-box>div {
  flex: 1;
}

.select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointer {
  cursor: pointer
}

/* styles for the ReadMe text */

.content-page {
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  letter-spacing: 0;
  flex: 1;
  overflow-y: auto;
  padding: 40px;
}

.content-page h1 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.25;
}

.content-page h2 {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  margin: 30px 0 0 0;
}

.content-page p {
  margin: 10px 0 0 0;
}

.content-page p span.image-container {
  max-width: none;
  position: relative;
  display: inline-block;
}

.content-page p span.image-container>img {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
}

.content-page div.table-wrap {
  margin: 10px 0 0 0;
  overflow-x: auto;
  background: #f4f5f7;
}

.content-page div.table-wrap table {
  margin: 10px 0 0 0;
  overflow-x: auto;
  border-collapse: collapse;
  display: table;
  border-spacing: 2px;
  border-color: grey;
}

.content-page div.table-wrap table thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.content-page div.table-wrap table tbody {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.content-page div.table-wrap table thead tr th, .content-page div.table-wrap table tbody tr td {
  background: #f4f5f7 center right no-repeat;
  padding-right: 15px;
  border: 1px solid #c1c7d0;
  padding: 7px 10px;
  vertical-align: top;
  text-align: left;
}

.content-page div.table-wrap table thead tr th {
  font-weight: bold;
}

.content-page div.table-wrap table tbody tr td {
  background: #ffffff;
}

.content-page ul {
  list-style-type: disc;
  margin: 10px 0 0 0;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.content-page ul li {
  display: list-item;
  text-align: -webkit-match-parent;
}

/* .graticule {
  fill: none;
  stroke: #343434;
  stroke-width: 1;
  stroke-dasharray: 1,1;
} */

.map-legend {
  position: absolute;
  color: #fff;
  z-index: 5;
  font-size: 0.75em;
  padding: 5px;
  width: 100%;
  pointer-events: none;
  touch-action: none;
}

.map-legend .content {
  background-color: rgba(0,0,0,0.85);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.75em;
  border: 1px solid #343434;
  border-radius: 3px;
  padding: 5px 0px;
  position: relative;
  justify-content: flex-end;
}

.map-legend .content .sub-content{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.map-legend .content .item {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  width: 160px;
}

.map-legend .content .item .circle {
  height: 10px;
  width: 10px;
  background-color: green;
  border: 1px solid red;
  border-radius: 50%;
  display: inline-block;
  top: 1px;
  position: absolute;
}

.map-legend .content .item .label-text {
  margin-left: 15px;
}

.map-legend .show-hide-legend-button {
    position: absolute;
    right: 13px;
    top: 9px;
    border: 1px solid #aaa;
    padding: 3px;
    border-radius: 5px;
    background-color: #111;
    z-index: 1;
    width: 80px;
    text-align: center;
    cursor: pointer;
}

.chart-rect-hover {
  stroke: white;
}

.linestring:hover {
  stroke: 'red'
}

.search_boundary {
  pointer-events: none;
}


/* svg {
  display: block;
} */

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}